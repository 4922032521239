import { useWindowWidth } from '@/hooks/useWindowWidth';

import styles from './BuyTicket.module.scss';
import { useRouter } from 'next/router';
import { TABLET_RESOLUTION } from '@/constants/constants';
import { BuyButtonLink } from '@/types';
import { pixelRunScript } from '@/utils/pixelRunScript';

export function BuyTicket({ links }: { links: BuyButtonLink[] }) {
  const width = useWindowWidth();
  const router = useRouter();
  const currentPath = router.asPath;
  const getButtonLink = (
    currentPath: string,
    links: BuyButtonLink[],
  ): string => {
    const link = links.find((link) => link.path === currentPath);
    return link ? link.buyPath : '/tickets';
  };
  const getButtonMetrics = (
    currentPath: string,
    links: BuyButtonLink[],
  ): string => {
    const link = links.find((link) => link.path === currentPath);
    return link ? link.vkId : 'buy-ticket';
  };

  const buttonLink = getButtonLink(currentPath, links);
  const buttonMetrics = getButtonMetrics(currentPath, links);

  const handleButtonClick = () => {
    pixelRunScript(buttonMetrics);
    router.push(buttonLink);
  };

  if (width && width < TABLET_RESOLUTION)
    return (
      <div className={styles.wrapper}>
        <div className={styles.button}>
          <img
            src="/svg/ticket.svg"
            width={24}
            height={24}
            alt="buy-ticket"
            onClick={handleButtonClick}
          />
        </div>
      </div>
    );

  return (
    <img
      src="/svg/buy.svg"
      width={180}
      height={40}
      alt="buy-ticket"
      className={styles.buy}
      onClick={handleButtonClick}
    />
  );
}
